// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/configs/axios'

export const postCustomJobsStart = createAsyncThunk('appUtility/getCustomJobsStart', async (req) => {
  const response = await axios.post(`v1/scheduler/customjob`, req)
  return response.data
})

export const getHubspotConsent = createAsyncThunk('appUtility/getHubspotConsent', async () => {
  const response = await axios.get('v1/crm/hubspot/oauth')
  setTimeout(() => {
    window.open(response.data.data, '_blank');
})
  return response.data;
})

export const updateHubspotCRM = createAsyncThunk('appUtility/updateHubspotCRM', async (organisation) => {
  const response = await axios.post('v1/crm/hubspot/connect', organisation)
  return response.data
})

export const getHubspotContact = createAsyncThunk('appUtility/getHubspotContact', async () => {
  const response = await axios.get('v1/crm/hubspot/contact')
  return response.data;
})

export const unsubscribeEmail = createAsyncThunk('appCampaign/unsubscribeEmail', async (_user) => {
  const response = await axios.post(`v1/callback/unsubscribe`, _user)
  return response.data
})

export const getEnrichmentFileAdmin = createAsyncThunk('appCampaign/getEnrichmentFileAdmin', async (id) => {
  const response = await axios.get(`v1/sadmin/enrichment/${id}`);
  return response.data
})


export const getPeopleAdminData = createAsyncThunk('appCampaign/getPeopleAdminData', async (_user) => {
  const response = await axios.post(`v1/sadmin/contacts/people`, _user)
  return response.data
})

export const updateEnrichmentFileAdmin = createAsyncThunk('appCampaign/updateEnrichmentFileAdmin', async (enrichment) => {
  const response = await axios.post(`v1/sadmin/enrichment/update`, enrichment)
  return response.data
})

export const updateEnrichmentStageAdmin = createAsyncThunk('appCampaign/updateEnrichmentStageAdmin', async (enrichment) => {
  const response = await axios.post(`v1/sadmin/enrichment/updatestage`, enrichment)
  return response.data
})


export const generateAPIKey = createAsyncThunk('appCampaign/generateAPIKey', async () => {
  const response = await axios.get(`v1/key/generate`)
  return response.data
})

export const createAPIKey = createAsyncThunk('appCampaign/createAPIKey', async (objData) => {
  const response = await axios.post(`v1/key/create`, objData)
  return response.data
})

export const createAccessCode = createAsyncThunk('appCampaign/createAccessCode', async () => {
  const response = await axios.post(`v1/key/accesscode/create`)
  return response.data
})

export const getAccessCode = createAsyncThunk('appCampaign/getAccessCode', async () => {
  const response = await axios.get(`v1/key/accesscode`)
  return response.data
})

export const deactivateKey = createAsyncThunk('appCampaign/deactivateKey', async (objData) => {
  const response = await axios.post(`v1/key/deactivate`, objData)
  return response.data
})


export const getAllAPIKey = createAsyncThunk('appCampaign/getAllAPIKey', async () => {
  const response = await axios.get(`v1/key`)
  return response.data
})

export const getWebHook = createAsyncThunk('appCampaign/getWebHook', async () => {
  const response = await axios.get(`v1/key/webhook`)
  return response.data
})

export const createWebHook = createAsyncThunk('appCampaign/createWebHook', async (objData) => {
  const response = await axios.post(`v1/key/webhook/create`, objData)
  return response.data
})

export const validateWebHook = createAsyncThunk('appCampaign/validateWebHook', async (objData) => {
  const response = await axios.post(`v1/key/webhook/validate`, objData)
  return response.data
})

export const appUtilitySlice = createSlice({
  name: 'appUtility',
  initialState: {
    hubspotContactData:[],
    enrichmentFileAdmin:[],
    allAPIKey:[],
    pdataAdmin: {},
    webhookData:{},
    accessCodeData:{}

  },
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(getHubspotContact.fulfilled, (state, action) => {
      state.hubspotContactData = action.payload.data
    })
    .addCase(getEnrichmentFileAdmin.fulfilled, (state, action) => {
      state.enrichmentFileAdmin = action.payload
    })
    .addCase(getAllAPIKey.fulfilled, (state, action) => {
      state.allAPIKey = action.payload
    })
    .addCase(getPeopleAdminData.fulfilled, (state, action) => {
      state.pdataAdmin = action.payload
    })
    .addCase(getWebHook.fulfilled, (state, action) => {
      state.webhookData = action.payload
    })
    .addCase(getAccessCode.fulfilled, (state, action) => {
      state.accessCodeData = action.payload
    })
  
  }
})

export default appUtilitySlice.reducer
